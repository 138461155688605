import '../Home/home.css';

export const Home = () => {
  return (
    <>
      <div id="page-container">
        <div id="home" className="content has-bg home py-5">
          <div className="content-bg" style={{ backgroundImage: "url(../assets/img/bg/bg-home.jpg);" }}
            data-paroller="true"
            data-paroller-type="foreground"
            data-paroller-factor="-0.25">
          </div>

          <div className="container home-content py-5">
            <h1>Welcome to Photoplay</h1>
            <h3>Teste Public Home Page</h3>
            <p>Dispose a lot of elements to the screen.<br />
              Use our <a href="#/">app</a> to bring a new world.
            </p>
            <a href="#/" className="btn btn-theme btn-primary">Explore More</a> <a href="#/" className="btn btn-theme btn-outline-white">Purchase Now</a><br />
            <br />
            or <a href="#/">subscribe</a> newsletter
          </div>

        </div >

        <div id="about" className="content" data-scrollview="true">
          <div className="container" data-animation="true" data-animation-type="animate__fadeInDown">
            <h2 className="content-title">About Us</h2>
            <p className="content-desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum consectetur eros dolor,<br />
              sed bibendum turpis luctus eget
            </p>
            <div className="row">
              <div className="col-lg-4">
                <div className="about">
                  <h3 className="mb-3">Our Story</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum posuere augue eget ante porttitor fringilla.
                    Aliquam laoreet, sem eu dapibus congue, velit justo ullamcorper urna,
                    non rutrum dolor risus non sapien. Vivamus vel tincidunt quam.
                    Donec ultrices nisl ipsum, sed elementum ex dictum nec.
                  </p>
                  <p>
                    In non libero at orci rutrum viverra at ac felis.
                    Curabitur a efficitur libero, eu finibus quam.
                    Pellentesque pretium ante vitae est molestie, ut faucibus tortor commodo.
                    Donec gravida, eros ac pretium cursus, est erat dapibus quam,
                    sit amet dapibus nisl magna sit amet orci.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <h3 className="mb-3">Our Philosophy</h3>
                <div className="about-author">
                  <div className="quote">
                    <i className="fa fa-quote-left"></i>
                    <h3>We work harder,<br /><span>to let our user keep simple</span></h3>
                    <i className="fa fa-quote-right"></i>
                  </div>
                  <div className="author">
                    <div className="image">
                      <img src="../assets/img/user/user-1.jpg" alt="Sean Ngu" />
                    </div>
                    <div className="info">
                      Sean Ngu
                      <small>Front End Developer</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <h3 className="mb-3">Our Experience</h3>
                <div className="skills">
                  <div className="skills-name">Front End</div>
                  <div className="progress mb-3">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-theme" style={{ width: "95%" }}>
                      <span className="progress-number">95%</span>
                    </div>
                  </div>
                  <div className="skills-name">Programming</div>
                  <div className="progress mb-3">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-theme" style={{ width: "90%" }}>
                      <span className="progress-number">90%</span>
                    </div>
                  </div>
                  <div className="skills-name">Database Design</div>
                  <div className="progress mb-3">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-theme" style={{ width: "85%" }}>
                      <span className="progress-number">85%</span>
                    </div>
                  </div>
                  <div className="skills-name">Wordpress</div>
                  <div className="progress mb-3">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-theme" style={{ width: "80%" }}>
                      <span className="progress-number">80%</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div id="team" className="content" data-scrollview="true">
          <div className="container">
            <h2 className="content-title">Our Team</h2>
            <p className="content-desc">
              alguma coisa aqui pra encher linguiça<br />
              vai fazer a gente ficar conhecido.
            </p>
            <div className="row">
              <div className="col-lg-6">
                <div className="team">
                  <div className="image" data-animation="true" data-animation-type="animate__flipInX">
                    <img src="../assets/img/user/user-1.jpg" alt="Jean Campos" />
                  </div>
                  <div className="info">
                    <h3 className="name">Jean Campos</h3>
                    <div className="title text-theme">FOUNDER</div>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.</p>
                    <div className="social">
                      <a href="#"><i className="fab fa-facebook-f fa-lg fa-fw"></i></a>
                      <a href="#"><i className="fab fa-twitter fa-lg fa-fw"></i></a>
                      <a href="#"><i className="fab fa-google-plus-g fa-lg fa-fw"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="team">
                  <div className="image" data-animation="true" data-animation-type="animate__flipInX">
                    <img src="../assets/img/user/user-2.jpg" alt="Cleiton Hoffmann" />
                  </div>
                  <div className="info">
                    <h3 className="name">Cleiton Hoffmann</h3>
                    <div className="title text-theme">WEB DEVELOPER</div>
                    <p>Donec quam felis, ultricies nec, pellentesque eu sem. Nulla consequat massa vierra quis enim.</p>
                    <div className="social">
                      <a href="#"><i className="fab fa-facebook-f fa-lg fa-fw"></i></a>
                      <a href="#"><i className="fab fa-twitter fa-lg fa-fw"></i></a>
                      <a href="#"><i className="fab fa-google-plus-g fa-lg fa-fw"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="quote" className="content bg-black-darker has-bg" data-scrollview="true">
          <div className="content-bg" style={{ backgroundImage: "url(../assets/img/bg/bg-quote.jpg)" }}
            data-paroller-factor="0.5"
            data-paroller-factor-md="0.01"
            data-paroller-factor-xs="0.01">
          </div>
          <div className="container" data-animation="true" data-animation-type="animate__fadeInLeft">
            <div className="row">
              <div className="col-lg-12 quote">
                <i className="fa fa-quote-left"></i> Passion leads to design, design leads to performance, <br />
                performance leads to <span className="text-theme">success</span>!
                <i className="fa fa-quote-right"></i>
                <small>Sean Themes, Developer Teams in Malaysia</small>
              </div>
            </div>
          </div>
        </div>


      </div>
    </>
  );
}
